import React from "react"

const titleStyle = {
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    color: "#404040",
    fontSize: "32px",
    textAlign: "left",
    letterSpacing: "1px"
}

const subTitleStyle = {
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    color: "#7f7f7f",
    fontSize: "32px",
    textAlign: "left",
    letterSpacing: "1px"
}

const sectionHeaderStyle = {
    fontFamily: "'Inter', sans-serif",
    fontWeight: "500",
    color: "#7f7f7f",
    fontSize: "12px",
    textAlign: "left",
    letterSpacing: "2px",
    marginBottom: "8px"
}

const sectionTitleStyle = {
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    color: "#404040",
    fontSize: "20px",
    textAlign: "left",
    letterSpacing: "0.5px",
    marginBottom: "12px"
}

const listStyle = {
    listStyle: "none"
}

const bulletStyle = {
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    color: "#a1a1a1",
    fontSize: "16px",
    textAlign: "left",
    letterSpacing: "1px",
    marginBottom: "8px"
}

const headerStyle = {
    marginBottom: "128px"
}

const sectionStyle = {
    marginBottom: "64px",
    maxWidth: "600px"
}

const linkStyle = {
    color: "#216ce0",
    textDecoration: "none",
}


export default class App extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {
        return (
            <div>
                <div style={headerStyle}>
                    <div style={titleStyle}>Matthew Jarvis Wall</div>
                    <div style={subTitleStyle}>Product Manager</div>
                </div>
                <div style={sectionStyle}>
                    <div style={sectionHeaderStyle}>CURRENTLY</div>
                    <div style={sectionTitleStyle}>I'm a Sr. Product Manager at Warner Bros. Discovery.</div>
                </div>
                <div style={sectionStyle}>
                    <div style={sectionHeaderStyle}>REACH OUT</div>
                    <div style={sectionTitleStyle}>I'm always open to discussing new opportunities or grabbing a coffee.</div>
                    <div style={bulletStyle}>⭢&nbsp;&nbsp;&nbsp;<a style={linkStyle} href="mailto:jarvis.wall@gmail.com">jarvis.wall@gmail.com</a></div>
                </div>
            </div>
        ); 
    }
  }
